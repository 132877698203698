<template>
  <b-col>
    <!-- form -->
    <validation-observer ref="mainBankAccountsDataForm">
      <b-form @submit.prevent="validationForm">
        <b-row>
          <b-col sm="12">
            <b-row
              style="justify-content: center;"
              class="my-1"
            >
              <b-col
                style="text-align: center"
              >
                <h3>
                  Cobrar mis depósitos
                </h3>
              </b-col>
            </b-row>
            <b-row class="mt-1 mb-3">
              <b-col>
                <span>
                  Para poder cobrar es necesario completar estos datos (la cuenta en Soles es requerida):
                </span>
              </b-col>
            </b-row>
            <b-card
              class="px-2 my-1"
            >
              <b-row>
                <!-- Header -->
                <b-col>
                  <span class="mt-1 mb-3">
                    Cobrar en cuenta
                  </span>
                  <b-badge variant="light-primary">
                    Soles
                  </b-badge>
                  <hr>
                </b-col>
              </b-row>
              <b-row
                class="justify-content-center"
              >
                <!-- Type Deposit -->
                <b-col
                  md="6"
                >
                  <b-row class="justify-content-center mt-1">
                    <b-form-group
                      label="Modo de cobro"
                      label-for="typeDepositPEN"
                      style="text-align: center;"
                    >
                      <b-form-radio-group
                        id="typeDepositPEN"
                        v-model="bankAccountPEN.typeDeposit"
                        class="text-center mt-1"
                        :options="optionsTypeDepositPEN"
                        value-field="value"
                        text-field="text"
                        name="typeDepositPEN"
                      />
                    </b-form-group>
                  </b-row>
                </b-col>
                <b-col
                  v-if="bankAccountPEN.typeDeposit == '1'"
                  md="12"
                  class="repeater-form justify-content-center"
                >
                  <b-row class="justify-content-center">
                    <!-- Account Holder -->
                    <b-col
                      md="9"
                      lg="6"
                      class="my-1"
                    >
                      <b-form-group
                        label="Escoger cuenta"
                        label-for="bankAccountPEN"
                      >
                        <b-form-select
                          id="bankAccountPEN"
                          v-model="bankAccountPEN.bankAccount"
                          :options="accountsPEN"
                          value-field="optionName"
                          text-field="optionName"
                          name="bankAccountPEN"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="bankAccountPEN.typeDeposit == '0'"
                  md="12"
                  class="repeater-form justify-content-center"
                >
                  <b-row class="justify-content-center">
                    <!-- Account Holder -->
                    <b-col
                      md="9"
                      class="my-1"
                    >
                      <b-form-group
                        label="A nombre de: "
                        label-for="isBankAccountHolderPEN"
                        style="text-align: center;"
                      >
                        <b-form-radio-group
                          id="isBankAccountHolderPEN"
                          v-model="bankAccountPEN.isBankAccountHolder"
                          class="text-center"
                          :options="optionsAccountHolder"
                          value-field="value"
                          text-field="text"
                          name="isBankAccountHolderPEN"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="bankAccountPEN.isBankAccountHolder == '1'"
                      md="9"
                      class="repeater-form justify-content-center my-1"
                    >
                      <b-form-group
                        label="Nombre del titular"
                        label-for="bankAccountHolderPEN"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nombre del titular"
                          rules="max:100"
                          vid="bankAccountHolderPEN"
                        >
                          <b-form-input
                            id="bankAccountHolderPEN"
                            v-model="bankAccountPEN.accountHolder"
                            :state="errors.length > 0 ? false:null"
                            name="bankAccountHolderPEN"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              class="px-2 mt-3"
            >
              <b-row>
                <!-- Header -->
                <b-col>
                  <span class="mt-1 mb-3">
                    Cobrar en cuenta
                  </span>
                  <b-badge variant="light-primary">
                    Dólares
                  </b-badge>
                  <hr>
                </b-col>
              </b-row>
              <b-row
                class="justify-content-center"
              >
                <!-- Type Deposit -->
                <b-col
                  md="6"
                >
                  <b-row class="justify-content-center mt-1">
                    <b-form-group
                      label="Modo de cobro"
                      label-for="typeDepositPEN"
                      style="text-align: center;"
                    >
                      <b-form-radio-group
                        id="typeDepositUSD"
                        v-model="bankAccountUSD.typeDeposit"
                        class="text-center mt-1"
                        :options="optionsTypeDepositUSD"
                        value-field="value"
                        text-field="text"
                        name="typeDepositUSD"
                      />
                    </b-form-group>
                  </b-row>
                </b-col>
                <b-col
                  v-if="bankAccountUSD.typeDeposit == '1'"
                  md="12"
                  class="repeater-form justify-content-center"
                >
                  <b-row class="justify-content-center">
                    <!-- Account Holder -->
                    <b-col
                      md="9"
                      lg="6"
                      class="my-1"
                    >
                      <b-form-group
                        label="Escoger cuenta"
                        label-for="bankAccountUSD"
                      >
                        <b-form-select
                          id="bankAccountUSD"
                          v-model="bankAccountUSD.bankAccount"
                          :options="accountsUSD"
                          value-field="optionName"
                          text-field="optionName"
                          name="bankAccountUSD"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col
                  v-if="bankAccountUSD.typeDeposit == '0'"
                  md="12"
                  class="repeater-form justify-content-center"
                >
                  <b-row class="justify-content-center">
                    <!-- Account Holder -->
                    <b-col
                      md="9"
                      class="my-1"
                    >
                      <b-form-group
                        label="A nombre de:"
                        label-for="isBankAccountHolderUSD"
                        style="text-align: center;"
                      >
                        <b-form-radio-group
                          id="isBankAccountHolderUSD"
                          v-model="bankAccountUSD.isBankAccountHolder"
                          class="text-center"
                          :options="optionsAccountHolder"
                          value-field="value"
                          text-field="text"
                          name="isBankAccountHolderUSD"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="bankAccountUSD.isBankAccountHolder == '1'"
                      md="9"
                      class="repeater-form justify-content-center my-1"
                    >
                      <b-form-group
                        label="Nombre del titular"
                        label-for="bankAccountHolderUSD"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Nombre del titular"
                          rules="max:100"
                          vid="bankAccountHolderUSD"
                        >
                          <b-form-input
                            id="bankAccountHolder"
                            v-model="bankAccountUSD.accountHolder"
                            :state="errors.length > 0 ? false:null"
                            name="bankAccountHolderUSD"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
        <b-row
          class="justify-content-center mt-4 mb-4"
        >
          <b-col
            sm="6"
            md="3"
          >
            <!-- return button -->
            <b-button
              class="my-1"
              variant="secondary"
              :to="{name:'BankAccounts-data'}"
              block
            >
              Volver
            </b-button>
          </b-col>
          <b-col
            sm="6"
            md="3"
          >
            <!-- submit button -->
            <b-button
              class="my-1"
              variant="primary"
              block
              type="submit"
            >
              Guardar y avanzar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-col>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormRadioGroup, BButton, BRow, BCol, BBadge, BFormGroup, BFormSelect, BFormInput,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default {
  components: {
    BCard,
    BForm,
    BFormSelect,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BBadge,
  },
  data() {
    return {
      required,
      email: '',
      ruc: '',
      optionsTypeDepositPEN: [
        { value: '0', text: 'Cheque' },
        { value: '1', text: 'Depósito bancario' },
      ],
      optionsTypeDepositUSD: [
        { value: '0', text: 'Cheque' },
        { value: '1', text: 'Depósito bancario' },
        { value: '2', text: 'No uso' },
      ],
      optionsAccountHolder: [
        { value: '0', text: 'El titular' },
        { value: '1', text: 'Un tercero' },
      ],
      bankAccountPEN: {
        typeDeposit: '',
        isBankAccountHolder: '',
        accountHolder: '',
        bankAccount: '',
      },
      bankAccountUSD: {
        typeDeposit: '',
        isBankAccountHolder: '',
        accountHolder: '',
        bankAccount: '',
      },
      accountsPEN: [],
      accountsUSD: [],
    }
  },
  watch: {
    bankAccountPEN: {
      handler() {
        for (let j = 0; j < this.accountsPEN.length; j += 1) {
          if (this.bankAccountPEN.bankAccount === this.accountsPEN[j].optionName) {
            this.bankAccountPEN.bank = this.accountsPEN[j]
            delete this.bankAccountPEN.bank.optionName
          }
        }
      },
      deep: true,
    },
    bankAccountUSD: {
      handler() {
        for (let j = 0; j < this.accountsUSD.length; j += 1) {
          if (this.bankAccountUSD.bankAccount === this.accountsUSD[j].optionName) {
            this.bankAccountUSD.bank = this.accountsUSD[j]
            delete this.bankAccountUSD.bank.optionName
          }
        }
      },
      deep: true,
    },
  },
  created() {
    const dataU = JSON.parse(localStorage.getItem('userData'))
    this.ruc = dataU.ruc
    this.email = dataU.email
    const banks = dataU.bankData ? dataU.bankData.bankAccounts : []
    const mainBankAccounts = dataU.mainBankAccountsData

    // Esto es para llenar los selects de cuentas en soles y dolares
    if (banks !== undefined && banks.length > 0) {
      for (let i = 0; i < banks.length; i += 1) {
        if (banks[i].completed && banks[i].currency.varDescripcion === 'Soles') {
          banks[i].optionName = banks[i].interbankAccountNumber.concat(' ', banks[i].nameBank)
          this.accountsPEN.push(banks[i])
        } else if (banks[i].completed && banks[i].currency.varDescripcion === 'Dólares') {
          banks[i].optionName = banks[i].interbankAccountNumber.concat(' ', banks[i].nameBank)
          this.accountsUSD.push(banks[i])
        }
      }
    }

    // Esto es para llenar los datos guardados
    if (mainBankAccounts !== undefined) {
      this.bankAccountPEN = mainBankAccounts.bankAccountPEN
      this.bankAccountUSD = mainBankAccounts.bankAccountUSD
    }
  },
  methods: {
    validationForm() {
      const bankPEN = {}
      const bankUSD = {}

      bankPEN.typeDeposit = this.bankAccountPEN.typeDeposit
      bankUSD.typeDeposit = this.bankAccountUSD.typeDeposit

      if (this.bankAccountPEN.typeDeposit === '0') {
        bankPEN.isBankAccountHolder = this.bankAccountPEN.isBankAccountHolder

        if (this.bankAccountPEN.isBankAccountHolder === '1') {
          bankPEN.accountHolder = this.bankAccountPEN.accountHolder
        }
      } else {
        bankPEN.bankAccount = this.bankAccountPEN.bankAccount
        bankPEN.bank = this.bankAccountPEN.bank
      }

      if (this.bankAccountUSD.typeDeposit === '0') {
        bankUSD.isBankAccountHolder = this.bankAccountUSD.isBankAccountHolder

        if (this.bankAccountUSD.isBankAccountHolder === '1') {
          bankUSD.accountHolder = this.bankAccountUSD.accountHolder
        }
      } else if (this.bankAccountUSD.typeDeposit === '1') {
        bankUSD.bankAccount = this.bankAccountUSD.bankAccount
        bankUSD.bank = this.bankAccountUSD.bank
      }

      this.$refs.mainBankAccountsDataForm.validate().then(success => {
        if (success) {
          useJwt.mainBankAccountsData({
            email: this.email,
            ruc: this.ruc,
            mainBankAccountsData: {
              bankAccountPEN: bankPEN,
              bankAccountUSD: bankUSD,
            },
          })
            .then(({ data }) => {
              const user = data.user.value
              localStorage.setItem('userData', JSON.stringify(user))
              store.dispatch(
                'elementVerticalMenu/setStatusStep',
                { step: 'MainBankAccounts-legal-data', status: user.mainBankAccountsData.completed },
                { root: true },
              )
              this.$router.replace('/registro/legal-carga-documentacion')
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Éxito',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Se ha guardado tu información con éxito.',
                      autoHideDelay: 5000,
                    },
                  })
                })
            })
            .catch(error => {
              console.log('errores', error)
              this.$refs.mainBankAccountsDataForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
